<template>
  <transition appear name="fade" :duration="100">
    <div
      v-if="showBody"
      class="modal-wrapper"
      tabindex="-1"
      @click.self.stop="handleOverlayClick"
      @wheel.stop.prevent="preventScroll" 
      @touchmove.stop.prevent="preventScroll"
      ref="modal"
    >
      <!-- :style="cssVars" -->
      <transition appear :name="animationMode" @after-leave="dismissModal">
        <div
            v-show="showBody && bodyAnimationFinished"
          class="modal"
          :class="[
            customClass,
            closable ? 'has-close-button' : '',
          ]"
        >
          <button
            v-if="closable && closable"
            class="modal__close"
            type="button"
            @click="closeClicked"
          >
            <svg
              v-if="closable && closable"
              @click="closeClicked"
              class="icon icon-close"
            >
              <use xlink:href="/static/images/sprite.svg#close"></use>
            </svg>
          </button>
          <div
            v-if="showHeader"
            class="modal__header"
          >
            <slot name="header">
            </slot>
          </div>
          <div
              @wheel.passive="allowScroll" @touchmove.passive="allowScroll" 
              class="modal__body">
            <slot name="body">
            </slot>
          </div>
          <div
            v-if="showFooter"
            class="modal__footer"
          >
            <slot name="footer">
            </slot>
          </div>
        </div>
      </transition>
    </div>
  </transition>
</template>

<script>
import debounce from 'lodash/debounce';
import { mapGetters } from 'vuex';
import { v4 as uuidv4 } from 'uuid';

import { submitLogDL_GA_plus_DB } from '../../utils/tracking';


export default {
  props: {
    'z_index':{
      default: '1000000'
    },
    name: {
      type: String,
      default: 'unnamed_modal'
    },
    customClass: {
      type: String,
    },
    value: {
      default: false,
    },
    closable: {
      type: Boolean,
      default: true
    },
    showHeader: {
      type: Boolean,
      default: true,
    },
    showFooter: {
      type: Boolean,
      default: true,
    },
    priority: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      windowHeight : undefined,
      windowWidth : undefined,
      bodyAnimationFinished: true,
      id: undefined
    }
  },
  computed: {
    ...mapGetters({
      currentOpenModal: 'user/currentOpenModal',
    }),

    showBody() {
      try {
        return this.currentOpenModal.id == this.id
      } catch (error) {
        return false;
      }
    },
    animationMode(){
      if(this.windowWidth >= 500){
        return "popup"
      }
      return "slide-up"
    },
  },
  watch: {
    showBody: function (newValue, _) {
      if (newValue) {
        submitLogDL_GA_plus_DB(this.$props.name, 'modal', {state: 'modalOpened'})
      } else {
        submitLogDL_GA_plus_DB(this.$props.name, 'modal', {state: 'modalClosed'})
      }
    },
    value() {
      if (this.value) {
          this.open();
      } else {
        this.close();
      }
    },
  },
  methods: {
    preventScroll(event) {
      // This method is intentionally left blank as the scroll event 
      // is being handled (prevented) by the modifiers in the template
    },
    allowScroll(event) {
      // This allows the scroll event within the modal content without further propagation
      event.stopPropagation();
    },
    updateWindowDimensions() {
      this.windowHeight = window.innerHeight;
      this.windowWidth = window.innerWidth;
    },
    registerModal() {
      // Dispatch the action to register the modal
      // Include any other properties you need, like priority or type
      this.$store.dispatch('user/registerModal', { modalId: this.id, name: this.$props.name, priority: this.$props.priority });
      if(this.value){
        this.open();
      }
    },
    removeModal() {
      // Dispatch the action to remove the modal
      this.$store.dispatch('user/removeModal', this.id);
    },
    open() {
      this.$emit('input', true);
      this.$store.dispatch('user/requestModalOpen', this.id);
      this.$emit('open');
    },
    bounceAnimation(){
      this.$nextTick(() => {
          this.bodyAnimationFinished = false;
          setTimeout(() => {
            this.bodyAnimationFinished = true;
          }, 100);  
      });
    },
    closeClicked(){
      this.$emit('close-clicked');
      this.close();
    },
    close() {
      // this.showBody = false;
      // this.$emit('input', false);
      // this.$emit('close');
      // this.$emit('close-clicked');
      // this.dismissModal();
      this.$store.dispatch('user/closeModal', this.id);
      return true;
    },
    // modalOpened(){
    //   // if(this.showBody){
    //   // this.showBody = true;
    //   submitLogDL_GA_plus_DB(this.$props.name, 'modal', {state:'modalOpened'})
    //   // }
    // },
    dismissModal(){
      if(!this.showBody){
        submitLogDL_GA_plus_DB(this.$props.name, 'modal', {state:'modalClosed'})
        this.$emit('input', false);
        this.$emit('close');
      }
    },
    handleWindowFocus() {
      this.bounceAnimation();
    },
    handleWindowBlur() {
      this.bounceAnimation();
    },
    handleOverlayClick(){
      if (this.closable) {
        this.close();
      }else{
        this.bounceAnimation();
      }
    }
  },
  created() {
    let self = this;
    self.id = 'modal_' + uuidv4();
    self.registerModal();

    self.windowHeight = window.innerHeight;
    self.windowWidth = window.innerWidth;
    self.debouncedUpdateWindowDimensions = debounce(this.updateWindowDimensions, 100);
    window.addEventListener("resize", (e) => {
      self.debouncedUpdateWindowDimensions();
    });
    window.addEventListener("orientationchange", (e) => {
      self.debouncedUpdateWindowDimensions();
    });
  },
  mounted() {
    if(this.$el.parentNode) {
      this.$el.parentNode.removeChild(this.$el);
    }
    document.body.appendChild(this.$el);

    window.addEventListener('focus', this.handleWindowFocus);
    window.addEventListener('blur', this.handleWindowBlur);
    // if(this.value == 'open'){
    //   submitLogDL_GA_plus_DB('modal_opened', this.$props.name, {})
    // }
  },
  beforeDestroy() {
    this.removeModal();
    // Remove event listeners to prevent memory leaks
    window.removeEventListener('focus', this.handleWindowFocus);
    window.removeEventListener('blur', this.handleWindowBlur);
  },
}
</script>
