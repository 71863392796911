import { mapGetters } from 'vuex';
import Modal from '../js/vue-apps/components/Modal.vue';
import Overlay from '../js/vue-apps/components/layout/Overlay.vue'
import modalsMixin from '../js/vue-apps/mixins/modalsMixin';


new Vue({
    name: 'navbar',
    components: {
        Modal,
        Overlay,
    },
    delimiters: ["<%", "%>"],
    mixins: [modalsMixin],
    store: window.STORE,
    data: {
        showMenuModal: false,
        showSupportModal: false,
        showUserModal: false,
        switchingToCurrency: undefined,
    },
    computed: {
        ...mapGetters({
            // 'hotelParams': 'pdp/hotelParams',
            // 'isValidRoomSearch': 'pdp/isValidRoomSearch',
            'currencies': 'user/availableCurrencies',
            'selectedCurrency': 'user/selectedCurrency',
        }),
        // cssVars () {
        //     return {
        //       "--z_index": 100,
        //       "--z_index_body": 99,
        //     }
        // },
        // popularCurrencies() {
        //     return Object.values(this.currencies)
        //         .filter(currency => currency.popular)
        //         .sort((a, b) => a.code < b.code ? -1 : 1);
        // },
        allCurrencies() {
            return Object.values(this.currencies)
            .sort((a, b) => {
                if (a.popular !== b.popular) {
                    return a.popular ? -1 : 1;
                } else {
                    return a.name > b.name ? 1 : -1;
                }
            });
        }
    },
    beforeMount(){
        this.$store.dispatch('user/initCurrency');
    },
    methods: {
        handleCurrencyClick(currency) {
            let self = this;
            if (currency) {
                self.switchingToCurrency = currency;
                self.$store.dispatch('user/switchCurrency', currency).then(() => {
                    // console.log('Currency has been set and API call completed, executing additional code');
                }).catch(error => {
                    console.error('Error setting currency:', error);
                }).finally(() => {
                    // self.showCurrencyModal = false;
                    this.closeModal('currencies-overlay');
                    this.closeModal('currencies-modal');
                    self.switchingToCurrency = undefined;
                });
            } else {
                this.showCurrencyModal = false;
            }
        }
    },
}).$mount('.header');
