var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition",
    { attrs: { appear: "", name: "fade", duration: 100 } },
    [
      _vm.showBody
        ? _c(
            "div",
            {
              ref: "modal",
              staticClass: "modal-wrapper",
              attrs: { tabindex: "-1" },
              on: {
                click: function ($event) {
                  if ($event.target !== $event.currentTarget) {
                    return null
                  }
                  $event.stopPropagation()
                  return _vm.handleOverlayClick($event)
                },
                wheel: function ($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.preventScroll($event)
                },
                touchmove: function ($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.preventScroll($event)
                },
              },
            },
            [
              _c(
                "transition",
                {
                  attrs: { appear: "", name: _vm.animationMode },
                  on: { "after-leave": _vm.dismissModal },
                },
                [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.showBody && _vm.bodyAnimationFinished,
                          expression: "showBody && bodyAnimationFinished",
                        },
                      ],
                      staticClass: "modal",
                      class: [
                        _vm.customClass,
                        _vm.closable ? "has-close-button" : "",
                      ],
                    },
                    [
                      _vm.closable && _vm.closable
                        ? _c(
                            "button",
                            {
                              staticClass: "modal__close",
                              attrs: { type: "button" },
                              on: { click: _vm.closeClicked },
                            },
                            [
                              _vm.closable && _vm.closable
                                ? _c(
                                    "svg",
                                    {
                                      staticClass: "icon icon-close",
                                      on: { click: _vm.closeClicked },
                                    },
                                    [
                                      _c("use", {
                                        attrs: {
                                          "xlink:href":
                                            "/static/images/sprite.svg#close",
                                        },
                                      }),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.showHeader
                        ? _c(
                            "div",
                            { staticClass: "modal__header" },
                            [_vm._t("header")],
                            2
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "modal__body",
                          on: {
                            "&wheel": function ($event) {
                              return _vm.allowScroll($event)
                            },
                            "&touchmove": function ($event) {
                              return _vm.allowScroll($event)
                            },
                          },
                        },
                        [_vm._t("body")],
                        2
                      ),
                      _vm._v(" "),
                      _vm.showFooter
                        ? _c(
                            "div",
                            { staticClass: "modal__footer" },
                            [_vm._t("footer")],
                            2
                          )
                        : _vm._e(),
                    ]
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }